import { APP_INITIALIZER, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";

import { EMPTY, forkJoin, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

import { ServicesModule } from "@services/services.module";
import { ResizeAndOrientationService } from "@services/resizeAndOrientationService.service";
import { ContextService } from "@services/context.service";
import { TimeZonesService } from "@services/http/time-zones.service";
import { LocalStorageKeys } from "@models/LocalStorageKeys";
import { RouteRedirectionService } from "src/app/core/util-services/route-redirection.service";
import { FetchVersionService } from "src/app/core/util-services/fetch-version.service";
import { LanguageStorageService } from "src/app/core/util-services/language-storage.service";
import { LocalStorageService } from "src/app/core/util-services/local-storage.service";
import { UtilService } from "src/app/core/util-services/util.service";
import { AppConfigFeatureFlagsProvider } from "@services/feature-flags/appconfig-feature-flags.provider";
import { ConfigCatFeatureFlagsProvider } from "@services/feature-flags/configcat-feature-flags.provider";
import { CustomHttpInterceptor } from "./interceptors/http.interceptor";

import { AuthService } from "./auth/auth.service";
import { AuthGuard } from "./auth/auth.guard";
import { AdminGuard } from "./auth/admin.guard";
import { UserGuard } from "./auth/user.guard";

import { PipesModule } from "./pipes/pipes.module";
import { CoreComponentsModule } from "./components/core.components.module";

import { SalesGuard } from "./auth/sales.guard";
import { UserManagementGuard } from "./auth/user-management.guard";
import { WizardGuard } from "./auth/wizard.guard";
import { CampaignsGuard } from "./auth/campaigns.guard";
import { RolesChangedGuard } from "./auth/roles-changed.guard";
import { DemoGuard } from "./auth/demo.guard";
import { FeatureFlagsGuard } from "./auth/feature-flags.guard";

export const fetchBuildDetails =
    (buildDetailsService: FetchVersionService) => () =>
        buildDetailsService.fetchBuildDetails();

const timeZoneChangesRange = () => {
    const now = new Date();

    const from = new Date(now);
    const to = new Date(now);

    from.setFullYear(from.getFullYear() - 5);
    to.setFullYear(to.getFullYear() + 5);

    return {
        from: Math.floor(from.getTime() / 1000),
        to: Math.floor(to.getTime() / 1000),
    };
};

const loadTimeZone =
    (
        context: ContextService,
        storage: LocalStorageService,
        timeZonesService: TimeZonesService,
        authService: AuthService
    ) =>
        () => {
            const loginInfo = context.getMe();
            const timeZoneName = loginInfo ? loginInfo.company.timeZone : "";

            if (!timeZoneName) {
                return EMPTY;
            }

            const token = authService.getToken();

            if (authService.tokenExpired(token)) {
                authService.logout();
                window.location.reload();
            }

            const timeZone$ = timeZonesService
                .getTimeZoneDetails(timeZoneName)
                .pipe(
                    catchError((error) => {
                        console.error("Error fetching time zone details:", error);
                        return of(null);
                    })
                );

            const timeZoneChanges$ = timeZonesService
                .getTimeZoneChanges({ timeZoneName, ...timeZoneChangesRange() })
                .pipe(
                    map(({ data }) => data.changes),
                    catchError((error) => {
                        console.error("Error fetching time zone changes:", error);
                        return of([]);
                    })
                );

            return forkJoin([timeZone$, timeZoneChanges$])
                .pipe(
                    tap(([timeZone, changes]) => {
                        const tz = {
                            ...timeZone,
                            changes,
                        };

                        storage.setItem(LocalStorageKeys.TIME_ZONE, tz);
                        storage.setItem(LocalStorageKeys.COMPANY_TIME_ZONE, tz);

                        return tz;
                    }),
                    catchError((error) => {
                        console.error("Error loading time zone:", error);
                        return of(null);
                    })
                )
                .toPromise();
        };

@NgModule({
    imports: [
        RouterModule,
        HttpClientModule,
        CoreComponentsModule,
        ServicesModule,
        PipesModule,
    ],
    providers: [
        UtilService,
        LocalStorageService,
        LanguageStorageService,
        AuthService,
        AuthGuard,
        RouteRedirectionService,
        AdminGuard,
        UserGuard,
        SalesGuard,
        ResizeAndOrientationService,
        {
            provide: APP_INITIALIZER,
            useFactory: fetchBuildDetails,
            deps: [FetchVersionService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: loadTimeZone,
            deps: [
                ContextService,
                LocalStorageService,
                TimeZonesService,
                AuthService,
            ],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (appConfigFeatureFlagsProvider: AppConfigFeatureFlagsProvider) => () => appConfigFeatureFlagsProvider.getFeatureFlags(),
            deps: [AppConfigFeatureFlagsProvider],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomHttpInterceptor,
            multi: true,
        },
        UserManagementGuard,
        WizardGuard,
        CampaignsGuard,
        RolesChangedGuard,
        DemoGuard,
        AppConfigFeatureFlagsProvider,
        ConfigCatFeatureFlagsProvider,
        FeatureFlagsGuard,
    ],
    exports: [
        ServicesModule,
        HttpClientModule,
        PipesModule,
        CoreComponentsModule,
    ],
})
export class CoreModule {}
