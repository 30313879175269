import { Injectable } from "@angular/core";

import { ConfigCatFeatureFlags, FeatureFlagConfig } from "@models/feature-flags/FeatureFlagConfig";

import { FeatureFlagsProvider } from "./feature-flags.provider";
import { AppConfigFeatureFlagsProvider } from "./appconfig-feature-flags.provider";
import { ConfigCatFeatureFlagsProvider } from "./configcat-feature-flags.provider";

@Injectable({ providedIn: "root" })
export class FeatureFlagsService implements FeatureFlagsProvider {
    constructor(
        private readonly appConfigProvider:  AppConfigFeatureFlagsProvider,
        private readonly configCatProvider: ConfigCatFeatureFlagsProvider,
    ) {}

    isFeatureFlagEnabled(feature: FeatureFlagConfig) {
        return this.resolveProvider(feature).isFeatureFlagEnabled(feature);
    }

    protected resolveProvider(feature: FeatureFlagConfig): FeatureFlagsProvider {
        if (!ConfigCatFeatureFlags[feature] || !this.configCatProvider) {
            return this.appConfigProvider;
        }

        return this.configCatProvider;
    }
}
