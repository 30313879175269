export const environment = {
    production: true,
    stage: "test",

    versionPollingInterval: 1000 * 60, // minute

    apiUrlPrefix: "https://test-api.brame-gamification.com",
    keyCloakApiUrlPrefix: "https://test-api.brame-gamification.com/keycloak",
    campaignDataUrlPrefix: "https://test-api.brame-gamification.com/campaigns-data", // needs to be parametrized for each evnironment
    buildServiceApiUrlPrefix: "https://test-api.brame-gamification.com/build",
    analyticsEndpoint: "analytics.brame-gamification.com",
    resourceServiceApiUrlPrefix: "https://test-api.brame-gamification.com/campaign-resource",

    serverlessApiBaseUrl: "https://test-serverless-api.brame-gamification.com",
    surveyApiUrlPrefix: "https://test-serverless-api.brame-gamification.com/survey-game/api",
    memoryApiBaseUrl: "https://test-serverless-api.brame-gamification.com/memory-game",
    scratchCardApiBaseUrl: "https://test-serverless-api.brame-gamification.com/scratch-card",
    pennySpinTheWheelApiBaseUrl: "https://test-serverless-api.brame-gamification.com/penny-stw",
    advancedSpinTheWheelApiBaseUrl: "https://test-serverless-api.brame-gamification.com/advanced-spin-the-wheel",
    prizeManagementApiBaseUrl: "https://test-serverless-api.brame-gamification.com/prize-management",
    pennyPathApiBaseUrl: "https://test-serverless-api.brame-gamification.com/penny-path-v2",
    pennyPenaltyApiBaseUrl:"https://test-serverless-api.brame-gamification.com/penny-penalty",
    pennySlotMachineApiBaseUrl: "https://test-serverless-api.brame-gamification.com/penny-slot-machine",
    quizApiBaseUrl: "https://test-serverless-api.brame-gamification.com/quiz",
    timeZonesApiBaseUrl: "https://test-serverless-api.brame-gamification.com/time-zone",
    eventsApiBaseUrl: "https://test-serverless-api.brame-gamification.com/events",

    campaignPreviewBaseUrl: "https://test-preview.brame-gamification.com",
    campaignDataStorage: "https://test-brame-campaign-data-storage.s3.amazonaws.com/campaign-data",
    pennyLeadsExportUrl: "https://a4hjfohonevpuoso7craxrv6pi0liclb.lambda-url.eu-central-1.on.aws",
    memoryGameLeadsExportUrl: "https://2x4jymilvcfmqmgjt7qb2eg7fq0zxmrc.lambda-url.eu-central-1.on.aws",
    pennySlotMachineLeadsExportUrl: "https://uiklanmr6wemkivbfakiq7t3ke0wukhs.lambda-url.eu-central-1.on.aws",
    pennyPathLeadsExportUrl: "https://bbvq3dvk34syqgn3hrcnvcpxii0upkub.lambda-url.eu-central-1.on.aws",
    pennyPenaltyLeadsExportUrl: "https://goqrfl356kj2x4hqbg7kbwzmgy0soskb.lambda-url.eu-central-1.on.aws",
    advancedSpinTheWheelExportUrl: "https://dyhtkvcrijfmnx56tciqvcuyhm0irbrv.lambda-url.eu-central-1.on.aws",

    integrationApp: {
        selfHosted: true,
        authEndpoint: "https://test-serverless-api.brame-gamification.com/integration-app",
        approvedIntegrations: [
            { key: "hubspot", flowKey: "brame-lead-universal-flow" },
            { key: "mailchimp", flowKey: "brame-lead-universal-flow" },
            { key: "salesforce-pardot", flowKey: "brame-lead-universal-flow" },
        ],
    },

    isDemoEnv: false,
    enableDemo:false,

    cookies: {
        brame_at: "brame_at",
        refresh_brame_at: "refresh_brame_at",
        session: "SESSION",
    },
    contextKeyName: "brame-context",
    // eslint-disable-next-line max-len
    fusionChartsKey: "PtB2dF-7wB4C5C3D5A3G3F3A2B8B7D2B3F6tgoD3B1d1fdzoG4I3I-7jD-17A3D5B3D5D4B3J4C3D7C5C2B4A4G1D2G1rmoE6E1C4gI4B3C8zyhD3D2E3vraA4B2C2zbnD1H4B3A5B3F4F3H3G4A19A32aikC6D3RH4moA-31A7C8A3wfA2IC1C5rA-16mD2D5F1A2B2B7A3D6A3C2E4E2D2C3u==",
    // Mixpanel
    mixpanelKey: "2a989804524f15cbdc8d6833074cbb4f",
    mixpanelProdEnv: false,
    backendAnalyticsEvents: {
        SUPPORTED_GAMES: [
            "quiz",
            "memory-game",
            "scratch-card",
            "survey",
        ],
        SUPPORTED_EVENTS: ["game_started", "game_finished", "lead_created"],
    },

    // Feature flags
    feautreFlagAppIdentifier: "3t9lzc2",
    feautreFlagProfileIdentifier: "p5hg1q5",
    feautreFlagEnvIdentifier: "ydn2fc1",
    featureFlagClientConfig: {
        region: "eu-central-1",
        credentials: {
            accessKeyId: "AKIATOHVODOV3D2XD7OV",
            secretAccessKey: "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",
        },
    },
    leadsExports: {
        endpoint: 'https://dx7zq7pebak7sghewegbqpy2iu0jzqbc.lambda-url.eu-central-1.on.aws/',
    },
    configCat: {
        sdkKey: 'configcat-sdk-1/pKDcCBJx0k2HOFPyJbWnQg/BAZCy8MtB0iIl11aF1kSkQ',
    },
};
