import { Component, OnInit } from "@angular/core";
import { ToastService } from "../../toast/toast.service";

@Component({
    selector: "app-toast",
    templateUrl: "./toast.component.html",
    styleUrls: ["./toast.component.scss"],
})
export class ToastComponent implements OnInit {
    message = "";

    constructor(private toastService: ToastService) {}

    ngOnInit() {
        this.toastService.toastState$.subscribe((message: string) => {
            this.message = message;
        });
    }

    closeToast() {
        this.message = "";
    }
}
